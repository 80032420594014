/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { MaterialInput, CardElevation, TitleIcon, ButtonDegrade } from '@findep/mf-landings-core'
import { Grid, Container, Select, FormControl, InputLabel } from "@material-ui/core"

//ICONS MATERIAL UI
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const cssSelect = css` 
  width:100%;
 `
const root = css`
  flex-grow: 1;
`
const buttonCss = css`
    display: flex;
    justify-content: flex-center;    
`
class ReferenciaCard extends Component {
  constructor(props) {
    super(props)
    this.state={
      _errors: {
        names: {
            error: false,
            errorType: 'Campo obligatorio',
        },
        lastName: {
            error: false,
            errorType: 'Campo obligatorio',
        },
        mothersName: {
            error: false,
            errorType: '',
        },
        razon_social: {
            error: false,
            errorType: ''
        },
        phone: {
            error: false,
            errorType: 'Se requiere un numero de 10 dígitos',
        }
    }
    }
  }

  render() {
    return (
      <CardElevation>
        <div css={root}>
          <Container justify="center" >

            <Grid container justify="center">
              <Grid item style={{ textAlign: "center" }}>
                <TitleIcon icon={PersonIcon} title={`${this.props.textTitleCard} ${this.props.id+1}`} textColor />
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={2} justify="center">
              {this.props.path === "referencias-comerciales" &&
                <Grid item xs={12} md={10}>
                  <MaterialInput
                    id={`referencias-razon-${this.props.id}-input`}
                    type="text"
                    name="razon_social"
                    pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                    accept="A-ZÑÁÉÍÓÚÜ\s.//"
                    label="Razón social"
                    transform={(value) => value.toUpperCase().slice(0, 50)}
                    onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                    required
                    error={this.props._errors.razon_social.error}
                    onError={(error) => this.props._handleError('razon_social', error, this.props.id)}
                    errorMessage={this.props._errors.razon_social.error ? 'El campo es obligatorio' : 'Ingresa el nombre comercial de la referencia'}
                    value={this.props.data.razon_social}
                    helperText="Ingresa el nombre comercial de la referencia"
                    disabled={this.props.activeInput}    
                  />
                </Grid>
              }
              
              <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-nombres-${this.props.id}-input`}
                  type="text"
                  name="names"
                  pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{2})?"
                  accept=" A-ZÑÁÉÍÓÚÜ"
                  label="Nombre(s)"
                  transform={(value) => value.toUpperCase().slice(0, 50)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  required
                  error={this.props._errors.names.error}
                  onError={(error) => this.props._handleError('names', error, this.props.id)}
                  errorMessage={this.props._errors.names.errorType}
                  disabled={                     
                    this.props.path === 'referencias-comerciales' 
                      ? (this.props._errors.razon_social.error || this.props.data.razon_social === '')
                      : this.props.activeInput
                    }
                  value={this.props.data.names}
                  helperText="Ingresa tu(s) nombre(s)"
                />
              </Grid>

               <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-apellido-paterno-${this.props.id}-input`}
                  type="text"
                  name="lastName"
                  pattern="[A-ZÑÁÉÍÓÚÜ]{2,}( [A-ZÑÁÉÍÓÚÜ]{2})?"
                  accept=" A-ZÑÁÉÍÓÚÜ"
                  label="Apellido Paterno"
                  transform={(value) => value.toUpperCase().slice(0, 50)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  required
                  error={this.props._errors.lastName.error}
                  onError={(error) => this.props._handleError('lastName', error, this.props.id)}
                  errorMessage={this.props._errors.lastName.errorType}
                  value={this.props.data.lastName}
                  disabled={this.props._errors.names.error || this.props.data.names === '' }
                  helperText="Ingresa tu apellido paterno"
                />
              </Grid>
             
              <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-apellido-materno-${this.props.id}-searchbar-input`}
                  type="text"
                  name="mothersName"
                  pattern="[A-ZÑÁÉÍÓÚÜ]{1,}( [A-ZÑÁÉÍÓÚÜ]{1})?"
                  accept=" A-ZÑÁÉÍÓÚÜ"
                  label="Apellido Materno"
                  transform={(value) => value.toUpperCase().slice(0, 50)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  error={this.props._errors.mothersName.error}
                  onError={(error) => this.props._handleError('mothersName', error, this.props.id)}
                  disabled={this.props._errors.lastName.error || this.props.data.lastName === '' }
                  errorMessage={this.props._errors.mothersName.errorType}
                  value={this.props.data.mothersName}
                  helperText="Ingresa tu apellido materno"
                />
              </Grid>
               
              <Grid item xs={12} md={10}>
                <FormControl variant="outlined" css={cssSelect}>
                  <InputLabel id="referencia-relacion-select-label">Relación</InputLabel>
                  <Select
                    onChange={(_event, component) => this.props.onChangeSelect('relacion', { value: _event.target.value + '' }, this.props.id)}
                    labelId="referencia-relacion-select-label"
                    id="referencia-relacion-select"
                    value={this.props.data.relacion}
                    disabled={this.props._errors.mothersName.error || this.props.data.lastName === '' || this.props._errors.lastName.error}
                    required={true}
                    label="Relación"
                  >
                    {
                      this.props.renderSelect()
                    }
                  </Select>
                </FormControl>
              </Grid> 

              <Grid item xs={12} md={10}>
                <MaterialInput
                  id={`referencias-telefono-${this.props.id}-input`}
                  type="text"
                  name="phone"
                  pattern="^([2-9])(?!0123456789|1234567890|0987654321|9876543210|\1{9})\d{9}$"
                  accept="0-9"
                  label="Telefono"
                  transform={(value) => value.slice(0, 10)}
                  onChange={(e, component) => { this.props.onChange(e, component, this.props.id) }}
                  required
                  onError={(error) => this.props._handleError('phone', error, this.props.id)}
                  errorMessage={this.props._errors.phone.error ? this.props._errors.phone.errorType : 'Ingresa tu telefono(10 digitos)'}
                  error={this.props._errors.phone.error}
                  disabled={!this.props.data.relacion}
                  value={this.props.data.phone}
                  helperText="Ingresa tu teléfono(10 dígitos)"
                />
              </Grid>              

                { (this.props.id + 1) === this.props.activeButton && 
                <Grid container justify="center">
                  <Grid item>
                    <br />
                    <div css={buttonCss} >
                      <ButtonDegrade
                        id="referencias-continuar-solicitud-button"
                        icon={ArrowForwardIcon}
                        textButton='Continuar con solicitud'
                        onClick={() => this.props.submitStepTwo()}
                        disabled={!this.props.btnSubmit}                        
                      />
                    </div>
                  </Grid>
                </Grid>
              }

            </Grid>
          </Container>
        </div>
      </CardElevation>
    );
  }
}


ReferenciaCard.propTypes = {
  onChange: PropTypes.func,
  

};


export default ReferenciaCard;
